const COS = require('cos-js-sdk-v5');
var uploadFile = (data,file,obj) => {
    var keyall = (new Date()).getTime() + '1' + Math.floor(Math.random() * 10000) // 当前时间戳+随机数

    var cos = new COS({
        // 必选参数
        getAuthorization:(options, callback)=>{
            callback({
                TmpSecretId: data.credentials.tmpSecretId,
                TmpSecretKey: data.credentials.tmpSecretKey,
                XCosSecurityToken: data.credentials.sessionToken,
                // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
            });
        },
        // 可选参数
        FileParallelLimit: 3, // 控制文件上传并发数
        ChunkParallelLimit: 3, // 控制单个文件下分片上传并发数
        ProgressInterval: 1000 // 控制上传的 onProgress 回调的间隔
    });
    // Toast.loading({
    //     duration: 0, // 持续展示 toast
    //     forbidClick: true,
    //     message: '上传中...',
    // });
    cos.putObject({
        Bucket: data.bucket,
        Region: data.region,
        Key: data.prefix + keyall, // 此处我们需要把后端返回上传文件路径拼接才能访问（没拼之前报错403）
        Body: file,
    },(err, datas)=>{
        if (datas.statusCode === 200) {
            // 上传成功得到的资源地址
            console.log(datas,"https://https://https://https://https://")
            let path = data.domain + "/" + data.prefix + keyall
            // Submit_log(file,path) // 提交上传记录
            obj(path)
        }
    });
}

export default uploadFile
