<template>
  <div class="Employeesettings">
    <div class="ishead">
      <div class="return cupsz" @click="$router.go(-1)">返回</div>
      <div>配置员工服务</div>
    </div>
    <div class="isbody">
      <div class="bodyLeft">
        <div style="font-size: 16px;color: #303133;">
          页面配置项
          <span style="font-size: 12px;color: #909499;">（展示在工作台的配置项数量最少1个，最多3个）</span>
        </div>

        <div class="bodyLeftBottomShang cupsz" v-if="!isSort" v-loading="isLoading">
          <div class="bodyLeftBottom drag">
            <div class="bodyLeftBottomx center">
              <div class="dragTopText" style="width : 90%;margin-bottom: 15px;">拖动可调整顺序</div>
              <div v-if="setList.group && setList.group.length <= 1" class="noData">
                <img src="@/assets/images/noData.png" alt />
                暂无数据
              </div>
              <!-- 排序模块 拖动组件 -->
              <!-- @end="onEnd" -->
              <draggable
                v-model="setListcoqy['group']"
                chosen-class="chosen"
                force-fallback="true"
                group="people"
                animation="200"
              >
                <transition-group>
                  <div
                    class="item dragFor"
                    v-for="(item) in setListcoqy['group']"
                    :key="item.group_id"
                    :id="item.group_id"
                  >
                    <div
                      class="dragForTitle dragdic"
                      :style="!item.service.length ? 'border : 0px;' : ''"
                    >
                      <div>{{item.name}}</div>
                      <img src="@/assets/images/staff/drag.png" alt />
                    </div>
                    <div v-if="item.service" style="width : 90%;margin-left: 5%;">
                      <!--  @end="onEnd($event,item)" -->
                      <draggable
                        v-model="item.service"
                        chosen-class="chosen"
                        force-fallback="true"
                        :group="'c'+item.group_id"
                        animation="200"
                      >
                        <transition-group>
                          <div
                            class="dragForC dragdic"
                            :style="index == item.service.length - 1 ? 'border : 0px;' : ''"
                            :id="initem.service_id"
                            v-for="(initem,index) in item.service"
                            :key="initem.service_id"
                          >
                            <div>{{initem.title}}</div>
                            <img src="@/assets/images/staff/drag.png" alt />
                          </div>
                        </transition-group>
                      </draggable>
                    </div>
                  </div>
                </transition-group>
              </draggable>
              <div class="dragBottom">
                <div class="cancel" @click="Unsort">取消</div>
                <div class="complete" @click="onEnd">完成</div>
              </div>
            </div>
          </div>
        </div>

        <div class="bodyLeftBottomShang" v-if="isSort">
          <div class="bodyLeftBottom">
            <div class="bodyLeftBottomx">
              <div class="bodyLeftBottomImg">
                <div class="bodyLeftBottomImgx">
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    style="display:none"
                    @change="upImg"
                  />
                  <img
                    style="width : 345px;max-height : 140px;"
                    :src="backgroundImg != '' && backgroundImg ? backgroundImg : require('@/assets/images/staff/background.png')"
                    class="borderImg"
                    alt
                  />
                  <div class="isforHide">
                    <div class="flexc" @click="changebImg">
                      <img title="编辑" src="@/assets/images/staff/chengeImg.png" alt />
                      <div>更换</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="personnelServices">
                <div v-if="setList['group'].length <= 1" class="noData">
                  <img src="@/assets/images/noData.png" alt />
                  暂无数据
                </div>

                <div
                  v-for="(item,index) in setList['group']"
                  :key="index"
                  class="stFor"
                  v-show="!(item.name == '未分组' && item.service.length == 0)"
                >
                  <div
                    class="personnelServicesText"
                    :style="item.service.length != 0 ? '' : 'border-bottom : 0px;'"
                    v-if="item.name == '未分组' ? false : true "
                  >
                    <div>{{item.name}}</div>
                    <div class="isImgShow" v-if="item.group_id">
                      <img
                        class="cupsz"
                        @mouseover="changeimg('edit',1)"
                        @mouseout="changeimg('edit',2)"
                        @click="changeGroup(item)"
                        :src="editImg"
                        alt
                      />
                      <img
                        class="cupsz"
                        @mouseover="changeimg('delete',1)"
                        @mouseout="changeimg('delete',2)"
                        @click="deleteGroup(item.group_id)"
                        :src="deleteImg"
                        alt
                      />
                    </div>
                  </div>

                  <div class="personnelServicesBody" v-if="item.service.length != 0">
                    <div
                      v-for="(inItem,i) in item.service"
                      :key="i"
                      :class=" i % 3 == 2 ? 'isfor' : 'isfor linx'"
                      :style="i > 2 ? 'border-top: 1px solid #F5F5F5' : ''"
                    >
                      <!-- 隐藏的  v-if="inItem.state == 1"-->
                      <div class="isforHide">
                        <div>
                          <img
                            title="编辑"
                            @click="editData({...inItem,group_id : item.group_id},item.state)"
                            src="@/assets/images/staff/edit_w.png"
                            alt
                          />
                        </div>
                        <div>
                          <img
                            title="删除"
                            src="@/assets/images/staff/delete.png"
                            alt
                            @click="deleteData(inItem.service_id)"
                          />
                        </div>
                      </div>

                      <!-- 左上角logo -->
                      <div class="isforDw" v-if="item.state == 1">
                        <img v-if="inItem.is_show == 1" src="@/assets/images/staff/use2.png" alt />
                        <img v-if="inItem.state != 1" src="@/assets/images/staff/disable.png" alt />
                      </div>

                      <!-- 服务名称 -->
                      <div class="isforImg">
                        <img
                          v-if="item.state == 1"
                          :style="inItem.state != 1 ? 'opacity: 0.5;' : ''"
                          src="@/assets/images/staff/information2.png"
                          alt
                        />
                        <img v-if="item.state == 2" src="@/assets/images/staff/Invalid.png" alt />
                      </div>
                      <div
                        class="isforData"
                        :style="inItem.state != 1 || item.state == 2 ? 'color: #303133;' : ''"
                      >{{inItem.title}}</div>
                    </div>
                    <div
                      v-for="list in _fill_list_3(item.service)"
                      :key="'i' + list"
                      style="width: 33.2%"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="bodyLeftBottomb">
                <div
                  class="leftButton cupsz"
                  @click="setList['group'].length >= 1 ? isSort = false : $message.error('暂无数据')"
                >
                  <img src="@/assets/images/staff/sort.png" alt /> 排序
                </div>
                <div class="leftButton cupsz" @click="addGroup = true">添加分组</div>
                <div class="cupsz leftButtonb" @click="editData()">添加配置项</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bodyRigth">
        <div style="font-size: 16px;color: #303133;margin-left: 14px;">工作台展示预览</div>
        <div>
          <img width="370px" style="margin-top: -16px;" src="@/assets/images/staff/preview.png" alt />
        </div>
        <div style="font-size: 14px;color: #303133;margin-left: 14px;margin-right: 24px;">
          <el-table
            :data="url"
            border
            :header-cell-style="headClass"
            width="341"
            empty-text="暂未启用员工服务"
          >
            <el-table-column prop="app_name" label="应用名称" width="80">
              <template slot-scope="scope">
                <div
                  style="width: 70px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                  :title="scope.row.app_name"
                >{{scope.row.app_name}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="agent_id" label="应用ID" width="80">
              <template slot-scope="scope">
                <div
                  style="width: 70px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                  :title="scope.row.agent_id"
                >{{scope.row.agent_id}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="url" label="应用主页配置地址" width="181">
              <template slot-scope="scope">
                <div style="display : flex">
                  <div
                    :title="scope.row.url"
                    id="copys"
                    style="width: 90px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                  >{{scope.row.url}}</div>
                  <div class="copyUrl cupsz" @click="copyUrl(scope.row.url)">复制地址</div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div style="margin-top: 20px;color : #555555">
            <h3>注：</h3>
            <p style="text-indent:2em;">使用员工服务模块的应用主页配置地址，必需在当前启用模板中包含员工服务时才能生成对应的主页配置地址。</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加配置项 -->
    <el-dialog
      title="新增/编辑配置项"
      :visible.sync="EditNew"
      width="520px"
      :before-close="()=>{EditNew = false}"
    >
      <div class="EditNewBody">
        <div>
          <div class>所属分组：</div>
          <div class="flex">
            <el-select v-model="fromData.group_id" placeholder="请选择" style="width: 221px;">
              <el-option
                v-for="item in setList['group']"
                :key="item.group_id"
                :label="item.name"
                :value="item.group_id"
                :disabled="item.state == 2"
              ></el-option>
            </el-select>
            <div class="addGroup cupsz flex" @click="addGroup = true">
              <img src="@/assets/images/staff/add.png" alt />
              新增分组
            </div>
          </div>
        </div>
        <div>
          <div>标题：</div>
          <div>
            <el-input
              type="text"
              placeholder="请输入标题"
              v-model="fromData.title"
              :maxlength="fromData.is_show?4:6"
              show-word-limit
              style="width: 307px;"
            ></el-input>
          </div>
        </div>
        <div>
          <div>配置联系人：</div>
          <div class="flex choices">
            <div class="choice cupsz" @click="SelectPersonnel">选择</div>
            <div v-if="fromData.user.length">
              <div v-if="fromData.user.length >= 3">
                已配置
                <sapn v-for="(item,index) in [fromData.user[0],fromData.user[1]]" :key="item">
                  <wxComponent type="userName" :openid="item" />
                  {{index == 0 ? "、" : ''}}
                </sapn>
                等{{fromData.user.length}}人
              </div>
              <div v-if="fromData.user.length < 3">
                已配置人员
                <sapn v-for="(item,index) in fromData.user" :key="item">
                  <wxComponent type="userName" :openid="item" />
                  {{index == 0 && fromData.user.length != 1 ? "、" : ''}}
                </sapn>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>状态：</div>
          <div>
            <el-radio v-model="fromData.state" label="1">启用</el-radio>
            <el-radio v-model="fromData.state" label="2">禁用</el-radio>
          </div>
        </div>
        <div>
          <div>是否展示到工作台：</div>
          <div>
            <el-switch v-model="fromData.is_show" @change="change_is_show" active-color="#13ce66" inactive-color="#E2E2E2"></el-switch>
          </div>
        </div>
        <div>
          <div>是否随机分配服务人员：</div>
          <div>
            <el-switch v-model="fromData.is_random" active-color="#13ce66" inactive-color="#E2E2E2"></el-switch>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="EditNew = false">取 消</el-button>
        <el-button type="primary" @click="preservationConfigure()">保 存</el-button>
      </span>
    </el-dialog>

    <!-- 添加分组 -->
    <el-dialog
      title="新增/编辑分组"
      :visible.sync="addGroup"
      width="420px"
      :before-close="() => {addGroup = false}"
    >
      <div class="EditNewBody">
        <div>
          <div style="width: 100px;">分组名称：</div>
          <div class="flex">
            <el-input
              type="text"
              placeholder="请输入标题"
              v-model="groupData.name"
              maxlength="6"
              show-word-limit
              style="width: 307px;"
            ></el-input>
          </div>
        </div>
        <div>
          <div style="width: 100px;">状态：</div>
          <div>
            <el-radio v-model="groupData.state" label="1">启用</el-radio>
            <el-radio v-model="groupData.state" label="2">禁用</el-radio>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGroup = false">取 消</el-button>
        <el-button type="primary" @click="preservationGroup()">保 存</el-button>
      </span>
    </el-dialog>

    <!-- <div class="bodyBottom">
      <div class="bodyCancel cupsz" @click="$router.go(-1)">取消</div>
      <div class="bodyPreservation cupsz" @click="pageSave">保存</div>
    </div>-->

    <img :src="calculation" ref="calculation" style="display:none;" alt />
  </div>
</template>

<script>
// 拖动组件
import draggable from "vuedraggable";
import wxComponent from "@/components/wx";
import {
  staffServiceGroupGetlist,
  deleteServices,
  deleteServiceGroup,
  update,
  setUpServices,
  setUpServicesGroup,
  editSort,
  preserve
} from "@/api/staff";
import uploadFile from "@/api/upImg";
import { getUploadToken } from "@/api/upload";

export default {
  name: "WorkbenchFrontEmployeesettings",
  components: {
    draggable,
    wxComponent
  },
  data() {
    return {
      isParentState: "", // 编辑子级时父级分组是否被禁用

      calculation: "", // 上传图片的url 用来计算图片比例

      isLoading: false, // 排序加载状态

      isSort: true, // 是否切换到排序的模块

      setList: {
        group: []
      }, // 服务列表

      setListcoqy: {
        // 服务列表 (拷贝 在排序中使用)
        group: []
      },

      EditNew: false, // 编辑新增弹框是否展示 (配置项)

      isEditNew: true, // 当前操作时新增还是编辑  新增 : true   编辑 : false

      fromData: {
        // 编辑新增配置项
        service_id: "", // 配置id
        title: "", // 配置名称
        state: "1", // 是否禁用
        is_show: true, // 是否显示
        sort: 0, // 排序
        user: [], // 联系人名单
        is_random: true // 是否随机分配服务人员
      },

      addGroup: false, // 编辑新增弹框是否展示 (分组)

      groupData: {
        group_id: "",
        name: "",
        state: "1"
      },

      backgroundImg: "",

      deleteImg: require("@/assets/images/staff/delete_b.png"), // 删除图标
      editImg: require("@/assets/images/staff/edit_b.png"), // 编辑图标

      oldData: [],
      url: [] // 应用主页配置url
    };
  },
  watch: {
    EditNew(data) {
      if (!data) {
        this.initializationFromData();
      }
    },
    addGroup(data) {
      if (!data) {
        this.initializationGroupData();
      }
    }
  },
  created() {
    this.getData();
  },
  methods: {
    headClass() {
      return "background:#F5F5F5;";
    },
    copyUrl(value) {
      let input = document.createElement("input");
      //设置id
      input.id = "xiaozhiCopy_id";
      //设置只读
      input.setAttribute("readonly", "readonly");
      //赋值
      input.setAttribute("value", value);
      //把input添加到body中
      document.body.appendChild(input);
      //选中input框，取值复制
      document.getElementById("xiaozhiCopy_id").select();
      document.execCommand("copy", false, null);
      //删除input框
      document.body.removeChild(input);
      this.$message.success("复制成功");
    },
    //改变是否展示到工作台开启状态
    change_is_show(e){
      if(e){  
          if(this.fromData.title.length>4){
            return this.$message({message: '如果展示到工作台标题不能超过4个字', type: 'warning'});
          }
      }
      this.fromData.is_show = e
    },
    pageSave() {
      preserve().then(data => {
        if (data.code == 200) {
          this.$message.success(data.message);
        } else {
          this.$message.error(data.message);
        }
      });
    },
    upImg(data) {
      // 图片修改完成
      if (data.target.files.length) {
        let fileSize = data.target.files[0].size / 1048576;
        //+(+fileSize).toFixed(2)
        let postData = {
          size: 3,
          mime: "image",
          action_type: "upload",
          type: "tx"
        };
        getUploadToken(postData).then(res => {
          uploadFile(res.data, data.target.files[0], this.postImg);
        });
      }
      // this.postImg('https://www.mgtx.cn/logo.png')
    },
    postImg(url) {
      // let img = new Image()
      this.calculation = url;
      let postData = {
        url,
        source: "staff_service",
        remark: "员工服务"
      };
      update(postData).then(data => {
        if (data.code == 200) {
          this.backgroundImg = url;
          this.$message.success(data.message);
        } else {
          this.$message.error(data.message);
        }
      });
    },
    changebImg() {
      // 点击修改背景图片
      document.getElementById("file").click();
    },
    changeimg(type, state) {
      if (type == "delete") {
        if (state == 1) {
          // 更改图片
          this.deleteImg = require("@/assets/images/staff/delete_r.png");
        } else {
          this.deleteImg = require("@/assets/images/staff/delete_b.png");
        }
      } else {
        if (state == 1) {
          // 更改图片
          this.editImg = require("@/assets/images/staff/edit_bl.png");
        } else {
          this.editImg = require("@/assets/images/staff/edit_b.png");
        }
      }
    },
    changeGroup({ ...data }) {
      // 分组编辑
      console.log(data, "skshdgfsjkdgfsjd");
      this.groupData = data;
      this.groupData.state += "";
      this.addGroup = true;
    },
    deleteGroup(group_id) {
      this.$confirm("此操作将永久删除该分组, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(_ => {
          delet();
        })
        .catch(_ => {});

      let delet = () => {
        deleteServiceGroup({
          group_id
        })
          .then(data => {
            if (data.code == 200) {
              this.$message.success(data.message);
              this.getData();
            } else {
              this.$message.error(data.message);
            }
          })
          .catch(err => {
            // this.$message.error("请求错误");
          });
      };
    },
    Unsort() {
      this.isSort = true;
      this.setListcoqy = JSON.parse(JSON.stringify(this.oldData));
    },
    onEnd() {
      // 完成拖动操作
      this.isLoading = true;
      let haveSubset = [];
      let ArrDataPlength = this.setListcoqy["group"].length;
      let ArrDataP = this.setListcoqy["group"].map((item, index) => {
        if (item.service.length && item.group_id) {
          haveSubset.push(...item.service);
        }
        return {
          id: item.group_id,
          type: "group",
          sort: ArrDataPlength - index
        };
      });
      // ArrDataP.splice(ArrDataPlength - 1, 1);
      let ArrDataClength = haveSubset.length;
      let ArrDataC = haveSubset.map((item, index) => {
        return {
          id: item.service_id,
          type: "service",
          sort: ArrDataClength - index
        };
      });
      this.postEditSort({ param: [...ArrDataC, ...ArrDataP] });
    },
    postEditSort(data) {
      // 拖动操作
      editSort(data)
        .then(data => {
          if (data.code == 200) {
            this.$message.success(data.message);
            this.isSort = true;
            this.getData();
          } else {
            this.isLoadingFales();
            this.$message.error(data.message);
          }
        })
        .catch(() => {
          this.isLoadingFales();
          // this.$message.error("请求错误");
        });
    },
    isLoadingFales() {
      setTimeout(() => {
        this.isLoading = false;
      }, 200);
    },
    initializationGroupData() {
      this.groupData = {
        // 初始化 分组
        group_id: "",
        name: "",
        state: "1"
      };
    },
    initializationFromData() {
      this.fromData = {
        // 初始化 配置项
        service_id: "",
        title: "",
        state: "1",
        is_show: true,
        sort: 0,
        user: [],
        is_random: true
      };
    },
    preservationGroup() {
      let that = this;
      function changeData() {
        setUpServicesGroup(that.groupData)
          .then(data => {
            if (data.code == 200) {
              that.initializationGroupData(); // 初始化数据 (分组)
              that.$message.success(data.message);
              that.getData(); // 刷新数据
              that.addGroup = false; // 关闭弹窗
            } else {
              that.$message.error(data.message);
            }
          })
          .catch(() => {
            // this.$message.error("请求错误");
          });
      }
      // 编辑和新增都是这个函数 (分组) // 禁用后将不会在页面，该分组及分组下的模块将不会展示在员工服务页面，确定禁用吗？
      if (this.groupData.state == 2) {
        let flag = false;
        this.groupData.service.forEach(item => {
          if (item.is_show == 1) {
            flag = true;
          }
        });
        if (flag) {
          this.$message({
            message: "该分组下有展示在工作台的配置项，无法禁用",
            type: "warning"
          });
          return;
        }
        this.$confirm(
          "禁用后将不会在页面，该分组及分组下的配置项将不会展示在员工服务页面，确定禁用吗？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(_ => {
            changeData();
          })
          .catch(_ => {});
      } else {
        changeData();
      }
    },
    preservationConfigure() {
      // 编辑和新增都是这个函数 (配置项)
      let fromDatac = {
        ...this.fromData,
        user: !this.fromData.user[0]?.user_id
          ? this.fromData.user.map(item => {
              return {
                user_id: item
              };
            })
          : this.fromData.user
      };
      if (this.isParentState == 2) {
        this.$message({
          message: "该配置项所属分组为禁用状态，无法将该配置项展示到工作台",
          type: "warning"
        });
        return;
      }

      fromDatac.is_show ? (fromDatac.is_show = 1) : (fromDatac.is_show = 2);
      fromDatac.is_random
        ? (fromDatac.is_random = 1)
        : (fromDatac.is_random = 2);
      setUpServices(fromDatac)
        .then(data => {
          if (data.code == 200) {
            this.initializationFromData(); // 初始化数据 (配置项)
            this.$message.success(data.message);
            this.getData(); // 刷新数据
            this.EditNew = false; // 关闭弹窗
          } else {
            this.$message.error(data.message);
          }
        })
        .catch(() => {
          // this.$message.error("请求错误");
        });
    },

    // 补全
    _fill_list_3(data) {
      let fill = 3 - (data.length % 3);
      return fill == 3 ? 0 : fill;
    },
    // 编辑服务
    editData(item, isParentState) {
      this.isParentState = isParentState;
      if (item) {
        this.fromData = item;
        this.fromData.state = this.fromData.state + "";
      }
      this.EditNew = true;
      this.isEditNew = false;
    },
    // 获取服务
    getData() {
      this.isLoading = true;
      staffServiceGroupGetlist().then(data => {
        if (data.code == 200) {
          this.url = data.data.url;
          this.setList = data.data;
          this.setListcoqy = JSON.parse(JSON.stringify(data.data));
          this.oldData = JSON.parse(JSON.stringify(data.data));
          this.backgroundImg = data.data?.cover_image?.url;
          this.setList["group"].push({
            name: "未分组",
            service: this.setList["service"],
            state: "1"
          });
          this.setList["group"].forEach(item => {
            if (!item.service.length) {
              return;
            }
            item.service.forEach(inItem => {
              if (inItem.is_show == 2) {
                inItem.is_show = false;
              } else {
                inItem.is_show = true;
              }
              if (inItem.is_random == 2) {
                inItem.is_random = false;
              } else {
                inItem.is_random = true;
              }
            });
          });
          this.isLoadingFales();
        } else {
          this.isLoadingFales();
          this.$message.error(data.message);
        }
      });
    },
    // 删除服务
    deleteData(service_id) {
      this.$confirm("此操作将永久删除该条配置项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(_ => {
          delet();
        })
        .catch(_ => {});

      let delet = () => {
        deleteServices({
          service_id
        })
          .then(data => {
            if (data.code == 200) {
              this.$message.success(data.message);
              this.getData();
            } else {
              this.$message.error(data.message);
            }
          })
          .catch(err => {
            // this.$message.error("请求错误");
          });
      };
    },
    // 选择人员
    SelectPersonnel() {
      let that = this;
      wx.invoke(
        "selectEnterpriseContact",
        {
          fromDepartmentId: 0, // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
          mode: "multi", // 必填，选择模式，single表示单选，multi表示多选
          type: ["user"], // 必填，选择限制类型，指定department、user中的一个或者多个
          // "selectedDepartmentIds": [],// 非必填，已选部门ID列表。用于多次选人时可重入，single模式下请勿填入多个id
          selectedUserIds: this.fromData.user // 非必填，已选用户ID列表。用于多次选人时可重入，single模式下请勿填入多个id
        },
        function(res) {
          if (res.err_msg == "selectEnterpriseContact:ok") {
            if (typeof res.result == "string") {
              res.result = JSON.parse(res.result); //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
            }
            that.fromData.user = [];
            var selectedUserList = res.result.userList; // 已选的成员列表
            selectedUserList.forEach(item => {
              that.fromData.user.push(item.id);
            });
          }
        }
      );
    }
  }
};
</script>

<style lang="less" scoped>
.stFor {
  width: 100%;
  background: white;
  border-radius: 4px;
  padding-bottom: 2px;
  margin-bottom: 10px;
}
.borderImg {
  border-radius: 6px;
}
.copyUrl {
  border: 1px solid #cccccc;
  border-radius: 20px;
  color: #555555;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  padding: 0px 4px;
}

.urlFor {
  height: 25px;
  margin-bottom: 5px;
  border-bottom: 1px solid #cccccc;
}
.noData {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  font-size: 14px;
  color: #333333;
  img {
    margin-bottom: 5px;
    width: 125px;
    // height: 95px;
  }
}

.flexc {
  display: flex;
  align-items: center;
  justify-content: center;
  // flex-direction: column;
  color: #fff;
  cursor: pointer;
  > div {
    // margin-top: 10px;
    font-size: 14px;
  }
  img {
    margin: 0 5px 0 0 !important;
  }
}

.dragTopText {
  color: #909499;
}

// 拖拽
.dragFor {
  width: 345px;
  background: #ffffff;
  border: 1px solid #d1dae2;
  border-radius: 4px;
  margin-bottom: 16px;
  .dragForTitle {
    width: 100%;
    height: 52px;
    line-height: 52px;
    border-bottom: 1px solid #e6e6e6;
  }
  .dragForC {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    font-weight: 500;
    color: #303133;
    border-bottom: 1px solid #e6e6e6;
  }
  .dragdic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-right: 15px;
    }
    > div:nth-child(1) {
      margin-left: 15px;
    }
    // >div:nth-child(2){
    //   height: 24px;
    //   padding: 0;
    //   margin: 0;
    // }
  }
}
.bodyRigth {
  max-width: 380px;
}
.drag {
  padding-top: 18px;
  .dragBottom {
    height: 72px;
    background: #ffffff;
    border: 1px solid #d6dbdf;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .cancel {
      margin-right: 22px;
      width: 78px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #d6dbdf;
      border-radius: 4px;
      line-height: 40px;
      text-align: center;
    }
    .complete {
      width: 78px;
      height: 40px;
      background: #0082ef;
      border-radius: 4px;
      line-height: 40px;
      text-align: center;
      color: #fff;
    }
  }
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  width: 390px;
}
.bodyLeftBottomShang {
  border: 1px solid #dde1e6;
  position: relative;
  width: 375px;
  overflow-x: hidden;
}

// 弹窗
.EditNewBody {
  .choice {
    width: 56px;
    height: 28px;
    background: #ffffff;
    border: 1px solid #dbdbdb;
    color: #333333;
    border-radius: 2px;
    line-height: 28px;
    text-align: center;
    margin-right: 10px;
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    > div:nth-child(1) {
      font-size: 14px;
      color: #303133;
      width: 120px;
    }
    > div:nth-child(2) {
      width: 350px;
    }
  }
  .addGroup {
    margin-left: 15px;
    justify-content: center;
    align-items: center;
    color: #0082ef;
    img {
      margin-right: 3px;
      width: 11px;
      height: 11px;
    }
  }
}

.choices {
  align-items: center;
  color: #0082ef;
}
.isforHide {
  position: absolute;
  border-radius: 2px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.695);
  z-index: -1;
  opacity: 0;
  transition: 0.5s all;
  img {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin: 0 10px;
  }
}

.linx {
  border-right: 1px solid #f5f5f5;
}
.isfors {
  background: #eeeeee !important;
  // cursor:no-drop;
}
.isfor {
  width: 33.2%;
  height: 115px;
  background: #ffffff;
  // border: 1px solid #d1dae2;
  // border-radius: 4px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .isforData {
    font-size: 14px;
    color: #303133;
  }
  .isforImg {
    // height: 18px;
    img {
      height: 40px;
      width: 40px;
    }
    margin-top: -4px;
    margin-bottom: 8px;
  }
  // img {
  //   width: 18px;
  //   height: 18px;
  //   margin-top: 4px;
  // }
  .isforDw {
    width: 15px;
    // height: 13px;
    position: absolute;
    right: 0;
    top: 0;
    img {
      position: relative;
      width: 100%;
      right: 2px;
      // height: 100%;
      margin-top: 0 !important;
    }
  }
}
.isfor:hover > .isforHide {
  z-index: 10;
  opacity: 1;
}

.cupsz {
  cursor: pointer;
}
.flex {
  display: flex;
}

.Employeesettings {
  // background: chartreuse;
  font-family: PingFang SC, Arial, Microsoft YaHei, sans-serif !important;
  min-height: calc(100vh - 110px);
  position: relative;
  // padding-bottom: 68px;
  .ishead {
    height: 48px;
    background: #f8f9fa;
    box-shadow: 0px 1px 0px #dbdbdb;
    position: relative;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #303133;
    // display: flex;
    // justify-content: center;
    // align-content: center;
    .return {
      width: 44px;
      height: 24px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.14901960784313725);
      border-radius: 2px;
      line-height: 24px;
      text-align: center;
      position: absolute;
      left: 32px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      top: 14px;
    }
  }

  .bodyLeftBottomx {
    width: 375px;
  }
  .center {
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .isbody {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    .bodyLeft {
      width: 375px;
      .bodyLeftBottom {
        background: #f5f5f5;
        border-radius: 2px;
        height: 701px;
        width: 390px;
        padding-bottom: 70px;
        overflow-y: auto;
        overflow-x: hidden;
        .bodyLeftBottomImg {
          padding: 15px 15px 15px 15px;
          position: relative;
        }
        .bodyLeftBottomImgx {
          position: relative;
          > div {
            img {
              width: 20px !important;
              height: 20px !important;
              margin: 0 20px;
            }
          }
        }
        .bodyLeftBottomImgx:hover > .isforHide {
          z-index: 10;
          opacity: 1;
        }
        .personnelServices {
          padding: 0 15px;
          > div {
            margin-bottom: 5px;
          }
          .personnelServicesBody {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          }
          .personnelServicesText {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 36px;
            border-bottom: 1px solid #f5f5f5;
            font-size: 12px;
            line-height: 36px;
            width: 100%;
            padding: 0 4%;
            > div {
              height: 22px;
              display: flex;
              align-items: center;
            }
            img {
              width: 16px;
              height: 16px;
              margin-left: 8px;
            }
          }
        }
        .bodyLeftBottomb {
          position: absolute;
          // width: 100%;
          left: 0px;
          right: 0px;
          bottom: 0px;
          height: 72px;
          background: #ffffff;
          border-top: 1px solid #d6dbdf;
          display: flex;
          justify-content: space-around;
          align-items: center;
          z-index: 1000;
          .leftButtonb {
            padding: 9px 38px;
            background: #ffffff;
            font-size: 16px;
            color: #0082ef;
            border: 1px solid #0082ef;
            border-radius: 4px;
          }
          .leftButton {
            padding: 9px 10px;
            border: 1px solid #d6dbdf;
            // height: 20px;
            background: #ffffff;
            color: #303133;
            font-size: 16px;
            font-weight: 500;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 14px;
              height: 14px;
              margin-right: 4px;
            }
          }
        }
      }
    }

    > div > div:nth-child(1) {
      margin-bottom: 12px;
    }
  }
}

.isImgShow {
  position: relative;
  left: -10px;
  z-index: -1;
  opacity: 0;
  transition: 0.3s all;
}

.personnelServicesText:hover > .isImgShow {
  left: 0;
  opacity: 1;
  z-index: 20;
}

.bodyBottom {
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.12);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    margin: 0 20px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    width: 68px;
    height: 32px;
    border-radius: 2px;
  }
  .bodyCancel {
    background: #ffffff;
    border: 1px solid #cccccc;
    font-weight: 400;
    color: #666666;
  }
  .bodyPreservation {
    background: #0078ef;
    color: #ffffff;
  }
}
</style>